<template>
  <div class="top-box" v-if="id">
    <span class="inline"></span>
    <span class="text">添加操作功能</span>
  </div>
  <div class="myBox box-shadow">
    <a-button type="primary" @click="handleAdd" style="margin-bottom: 8px">添加行</a-button>
    <a-table class="table" bordered :data-source="dataSource" :columns="columns" :pagination="false">
      <template #control="{ record }">
        <div class="flex">
          <span style="color:red; margin-right:5px;">*</span>
          <a-input v-model:value="record.control"
          placeholder="请输入操作功能名称"
          :maxlength="100"
          autocomplete="off"
          />
        </div>
      </template>
      <template #control_code="{ record }">
        <div class="flex">
          <span style="color:red; margin-right:5px;">*</span>
          <a-input v-model:value="record.control_code"
          placeholder="请输入操作功能id"
          autocomplete="off"
          type="number"
          :disabled="record.control_code && record.id ? true : false"/>
        </div>
      </template>
      <template #remarks="{ record }">
        <a-input v-model:value="record.remarks"
        placeholder="请输入备注"
        :maxlength="100"
        autocomplete="off"/>
      </template>
      <template #operation="{ record }">
        <a-button type="link" size="small" class="col-red" @click="onDelete(record.key)">删除</a-button>
      </template>
    </a-table>
    <div class="txt-r" style="margin-top:10px;">
      <a-button type="primary" style="margin-bottom: 8px" @click="saveData">保存</a-button>
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, onMounted, reactive, Ref, ref, UnwrapRef } from 'vue'
import {
  addControl,
  getPropsFun
} from '@/api/index'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'createControl',
  props: ['id'], // 项目id
  setup(props, context) {
    const router = useRouter()
    const formRef = ref()
    const columns = [
      {
        title: '操作功能名称',
        dataIndex: 'control',
        slots: { customRender: 'control' }
      },
      {
        title: '操作功能id',
        dataIndex: 'control_code',
        slots: { customRender: 'control_code' },
        width: '15%'
      },
      {
        title: '备注',
        dataIndex: 'remarks',
        slots: { customRender: 'remarks' }
      },
      {
        title: '',
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
        width: 30
      }
    ]
    const dataSource = ref([
      {
        key: '0',
        control: '',
        control_code: null,
        remarks: ''
      }
    ])
    const getPropsFunHttp = () => {
      getPropsFun({
        prop_id: parseInt(props.id)
      }).then(res => {
        if (res.data && res.data.control_list.length > 0) {
          dataSource.value = res.data.control_list.map((item) => {
            item.key = item.id
            return item
          })
        }
        console.log(res)
      })
    }
    const chenck = () => {
      for (let i = 0; i < dataSource.value.length; i++) {
        const control = dataSource.value[i].control
        const control_code = dataSource.value[i].control_code
        if (!control) {
          message.error('请先输入操作功能名称')
          return false
        }
        if (!control_code) {
          message.error('请先输入操作功能id')
          return false
        }
      }
      return true
    }
    const count = computed(() => dataSource.value.length + 1)
    const onDelete = (key) => {
      dataSource.value = dataSource.value.filter(item => item.key !== key)
    }
    const handleAdd = () => {
      const newData = {
        key: `${count.value}`,
        control: '',
        control_code: null,
        remarks: ''
      }
      if (chenck()) {
        dataSource.value.push(newData)
      }
    }
    const getControlList = () => {
      return dataSource.value.filter(item => {
        return item.control_code && item.control
      }).map(item => {
        return {
          control: item.control,
          control_code: parseInt(item.control_code),
          remarks: item.remarks
        }
      })
    }
    const addControlHttp = () => {
      // if (getControlList().length > 0) {
      addControl({
        prop_id: parseInt(props.id),
        control_list: getControlList()
      }).then(res => {
        message.success('添加操作功能成功')
        getPropsFunHttp()
      })
      // }
    }
    const saveData = () => {
      if (!chenck()) return
      addControlHttp()
    }
    onMounted(() => {
      getPropsFunHttp()
    })
    return {
      columns,
      onDelete,
      handleAdd,
      dataSource,
      count,
      saveData
    }
  }
})
</script>
<style lang="scss" scoped>
.myBox{
  padding: 20px;
}
.table{
  box-shadow: none;
}
.flex{
  // background: red;
}
.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    // padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>
